import React from 'react';
import './bulma/css/bulma.css'
import './icon/icon.css'
import {Link} from 'react-router-dom'
import logo from './img/logo_red.png';

function Navigation() {
  const [isActive, setIsActive] = React.useState(false);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="!#">
          <img src={logo} width="112" height="28" alt="petio logo" />
        </a>
        <div
          onClick={() => {
            setIsActive(!isActive);
          }}
          role="button"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>
      <div
        id="navbarBasicExample"
        className={`navbar-menu ${isActive ? "is-active" : ""}`}
      >
        <div className="navbar-start">
          <Link to='/search' className="navbar-item"><i className="gg-box is-inline-flex mr-3" />商品情報</Link>
          <div className="navbar-item"><i className="gg-log-out is-inline-flex ml-1 mr-5" />ログアウト</div>
          <div className="navbar-item"><i className="gg-edit-markup is-inline-flex mr-3" />設定</div>
          <div className="navbar-item"><i className="gg-home is-inline-flex mr-3" />ホーム</div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
