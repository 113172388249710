import React from 'react';
import '../bulma/css/bulma.css'
import './ItemList.css'
import Item from '../Item/Item'
import Search from '../Search'

function ItemList() {
  return (
    <div className="mb-5">
      <Search />
      <div className="columns mt-5 pt-5 is-danger">
        <div className="column is-10 is-offset-1 is-10-mobile is-offset-1-mobile title has-text-centered " >
            JAN/商品名
        </div>
      </div>
      <Item code={'4903588261855'}
            name={'Ｅ－Ｓｔｒｅｔｃｈ　スキンＴシャツ　ダークブルーボーダー　ＸＳ'} />
      <Item code={'4903588109416'}
            name={'ジャペル５０周年　素材そのまま　さつまいも　やわらかスティックタイプ　２８０ｇ'} />
      <Item code={'4903588109997'}
            name={'ＦＭ　九州産鶏ササミのモチモチお肉　細切り　４５ｇ'} />
      <Item code={'4903588114724'}
            name={'極上ささみ　巻きガムミニ　６本入'} />
      <Item code={'4903588114816'}
            name={'おなかにうれしい　ササミとチーズ　１７０ｇ'} />
      <Item code={'4903588117862'}
            name={'ｓｔｙｌｅＯＮＥ　ササミソフト細切りタイプ　１７０ｇ'} />
      <Item code={'4903588120442'}
            name={'乳酸菌のちから　ゼリータイプ　ＭＩＸ　１６ｇ×２０個入'} />
      <Item code={'4903588123399'}
            name={'ライフ　極上ささみ　細切りソフト　１５０ｇ＋１０ｇ'} />
      <Item code={'4903588125294'}
            name={'おなかにうれしい　ササミとチーズ　７歳からの健康ケア　１５０ｇ'} />
      <Item code={'4903588132537'}
            name={'乳酸菌のちから　ゼリータイプ　リッチミルクヨーグルト風味　１６ｇ×２０個入'} />
    </div>
  );
}

export default ItemList;