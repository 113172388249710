import React from 'react';
import '../bulma/css/bulma.css'
import './ItemDetails.css'
import Navigation from '../Navigation'

function ItemDetails() {

  return (
    <div>
      <Navigation />
      <div className="has-text-centered mb-5">
        <iframe height="400px" width="400px" src="https://fs.petio.com/360view/4903588261855/HTML5Viewer.html" />
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          品番
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          W26185
        </div>
      </div>
      <div className="columns pt-0">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          品名
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          Ｅ－Ｓｔｒｅｔｃｈ　スキンＴシャツ　ダークブルーボーダー　ＸＳ
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          JAN
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          4903588261855
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          大分類
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          10
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          PBNB
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          2
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          食品用品
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          2
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          廃番
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          0
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          3ヶ月前出荷数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          2ヶ月前出荷数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          １ヶ月前出荷数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          3ヶ月平均出荷数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          月初在庫数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          当月仕入数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          当月出荷数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          移管中在庫数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          有効在庫数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          在庫月数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          直近納品予定数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          直近納品予定日
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          当月納品予定数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_box" >
          xx
        </div>
      </div>
      <div className="columns pb-5">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile title_box has-text-centered" >
          翌月納品予定数
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile content_end_box" >
          xx
        </div>
      </div>
    </div>
  );
}

export default ItemDetails;