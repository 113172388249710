import React from 'react';
import '../bulma/css/bulma.css'
import './Item.css'
import { Link } from 'react-router-dom'

interface Props {
  code: string,
  name: string,
}

class Item extends React.Component<Props> {
  props:Props;
  
  constructor(props: Props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div className="columns">
        <div className="column is-2 is-offset-1 is-offset-1-mobile is-10-mobile item-code-box" >
          {this.props.code} <Link to='/item_details' className="button is-small is-rounded is-danger" >詳細</Link>
        </div>
        <div className="column is-8 is-offset-1-mobile is-10-mobile item-name-box" >
          {this.props.name}
        </div>
      </div>
    );
  }
}

export default Item;