import React from 'react';
import './bulma/css/bulma.css'
import { Link } from 'react-router-dom'
import logo from './img/logo_red.png';

function Login() {
  return (
    <div>
      <div className="columns mt-5 pt-5">
        <div className="column has-text-centered">
          <img src={logo} width="133" height="40" alt="petio logo" />
        </div>
      </div>
      <div className="columns mt-5 pt-5">
        <div className="column is-10 is-offset-1 is-10-mobile is-offset-1-mobile has-text-centered" >
          <input type="text" placeholder="id" className="input"/>
        </div>
      </div>
      <div className="columns has-text-centered">
        <div className="column is-10 is-offset-1 is-10-mobile is-offset-1-mobile has-text-centered">
          <input type="text" placeholder="password" className="input" />
        </div>
      </div>
      <div className="columns mt-5 pt-5">
        <div className="column has-text-centered">
          <Link to='/search' className="button is-medium is-danger" >ログイン</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
