import React from 'react';
import './bulma/css/bulma.css'
import './icon/icon.css'
import { Link } from 'react-router-dom'
import Navigation from './Navigation'

function Search() {
  return (
    <div>
      <Navigation/>
      <div className="columns mt-5 pt-5">
        <div className="column is-10 is-offset-1 is-10-mobile is-offset-1-mobile has-text-centered" >
          <div className="field is-grouped">
            <input type="text" placeholder="JAN" className="input mr-1"/>
            <Link to='/item_list' className="navbar-item">
              <i className="gg-search mt-0" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
